import React from "react"
import styled from "styled-components"

export const SimpleButton = styled.button`
  width: min-content;
  white-space: nowrap;
  min-height: 40px;
  max-width: ${props => props.maxWidth};
  border: none;
  border-radius: 4px;
  color: ${props => props.theme.beige()};
  border-color: ${props => props.theme.beige()};
  border-width: 2px;
  border-style: solid;
  background: transparent;
  transition: 0.9s all;
  cursor: pointer;
  letter-spacing: 4px;
  font-size: 14;
  font-weight: 600;
  padding: 4px 10px;
  outline: none;
  &:hover {
    border-color: transparent;
    background: ${props => props.theme.beige(0.1)};
    background-color: ${props => props.theme.beige(0.1)};
  }
`

const StyledContactForm = styled.form`
  display: block;
  text-align: left;
  width: 80%;
  max-width: 400px;
  margin: auto;
  h3 {
    color: ${props => props.theme.beige()};
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 5px;
  }
  .hidden {
    height: 0px;
    display: hidden;
    visibility: hidden;
  }
  label {
    margin-top: 1rem;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    justify-items: left;
    font-size: 13px;
    span.labelText {
      margin-bottom: 4px;
    }
    :focus-within {
      span.labelText {
        color: ${props => props.theme.orange()};
        transition: color 0.4s;
      }
    }
  }
  p {
    width: 100%;
  }
  input,
  textarea {
    width: 100%;
    background: transparent;
    background-color: transparent;
    border-color: white;
    border-style: solid;
    border-width: 0 0 3px 0;
    padding: 0;
    outline: none;
    font-size: 12px;
    line-height: 16px;
    color: white;
    border-radius: 0;
    -webkit-autofill: rgba(0, 0, 0, 0);
    /* Change Autocomplete styles in Chrome*/
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
      background-color: rgba(1, 0, 0, 0.1);
    }
  }

  input {
    height: 30px;
  }

  textarea {
    width: 100%;
    height: 90px;
  }

  .buttonContainer {
    margin-top: 12px;
    width: 100%;
    text-align: right;
  }
`

const ContactForm = () => {
  return (
    <StyledContactForm
      id="contact"
      name="contact"
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
    >
      <h3>Get in Touch</h3>
      <p className="hidden">
        <label>
          Don’t fill this out if you're human: <input name="bot-field" />
        </label>
      </p>
      <input type="hidden" name="form-name" value="contact" />
      <p>
        <label>
          <span className="labelText">Name: </span>
          <input type="text" name="name" />
        </label>
      </p>
      <p>
        <label>
          <span className="labelText">Email: </span>
          <input type="email" name="email" />
        </label>
      </p>
      <p>
        <label>
          <span className="labelText">Message: </span>
          <textarea name="message" />
        </label>
      </p>
      <div className="buttonContainer">
        <SimpleButton style={{ borderRadius: "6px" }} type="submit">
          Send
        </SimpleButton>
      </div>
    </StyledContactForm>
  )
}

export default ContactForm

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ThemeProvider, createGlobalStyle } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useCookies } from "react-cookie"

import "./layout.css"

import AgeGate from "./AgeGate"

export const themeStyles = {
  brown: function (opacity = 1) {
    return `rgba(28, 11, 6, ${opacity})`
  },
  orange: function (opacity = 1) {
    return `rgba(188, 103, 44, ${opacity})`
  },
  beige: function (opacity = 1) {
    return `rgba(203, 138, 94, ${opacity})`
  },
  black: function (opacity = 1) {
    return `rgba(32, 25, 27, ${opacity})`
  },
  mobileBreakPoint: "700px",
  headerHeight: "50px",
  minHeight: `calc(100vh - 2rem)`,
}

const GlobalStyle = createGlobalStyle`
  html::-webkit-scrollbar  {
    display: none; 
  }
  html {
    box-sizing: border-box;
    font-size: 62.5%;
    font-family: Verdana, sans-serif;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    top: 0;
    padding: 0;
    margin: 0;
    line-height: 1;
    font-family: 'Poppins', sans-serif;
    font-size: 1.6rem;
  } 
  a {
    text-decoration: none;
    letter-spacing: 5px;
    color: white;
    cursor: pointer;
    &:hover{
      color: ${themeStyles.orange()}
    };
  } 
  h1, h2, h3, h4 {
    letter-spacing: 2.5px;
    font-family: 'Tinos', serif;
    text-transform: uppercase;
  }
  h1, h2 {
    font-size: 28px;
    color: ${themeStyles.orange()}
  }
  h3, h4 {
    font-size: 18px;
    color: ${themeStyles.beige()}
  }
  p {
    color: white;
    width: 80%;
    max-width: 900px;
    margin: 0;
    text-align: left;
    letter-spacing: 1.5px;
    @media (max-width: 600px) {
      font-size: 12px;
      width: 90vw;
    }
  }
  h2.decTitle {
    color: white;
    font-size: 30px;
    letter-spacing: 6px;
    text-align: center;
    margin: 0;
    padding: 8px;
    text-transform: uppercase;
    border-width: 2px 0 2px 0;
    border-style: solid;
    border-color: white;
  } 
  .gatsby-focus-wrapper {
    width: 100vw;
  }
`

const Layout = ({ children }) => {
  const [cookies, setCookie] = useCookies(["over21"])
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.document.documentMode) return
      else setReady(true)
    }
  })
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={themeStyles}>
      <div>
        <GlobalStyle />
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <div
          style={{
            padding: `0`,
            margin: "0",
          }}
        >
          <main>{children}</main>
          {ready && !cookies.over21 && (
            <AgeGate
              handleClick={() => {
                setCookie("over21", true, { maxAge: 3600000 })
              }}
            />
          )}
        </div>
      </div>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from "react"
import styled from "styled-components"
import { SimpleButton } from "./ContactForm"
import Logo from "./Logo"

const StyledAgeGate = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.black()};
  z-index: 12;
  position: fixed;
  top: 0;
  transition: opacity 2s;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    width: 120px;
    margin: 0 30px;
  }
`

const AgeGate = ({ colorOne, colorTwo, handleClick }) => {
  return (
    <StyledAgeGate>
      <Logo />
      <h2>Are You at Least 21 Years of Age?</h2>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <SimpleButton
          width="180px"
          colorOne="white"
          colorTwo="white"
          colorThree="navy"
          colorFour="navy"
          onClick={handleClick}
        >
          Yes
        </SimpleButton>
        <a
          style={{ width: "min-content" }}
          href="https://hotwheels.mattel.com/shop"
        >
          <SimpleButton
            width="180px"
            colorOne="white"
            colorTwo="white"
            colorThree="red"
            colorFour="red"
          >
            No
          </SimpleButton>
        </a>
      </div>
    </StyledAgeGate>
  )
}

export default AgeGate
